import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Avatar, Box, Card, Stack, Typography } from '@mui/material';
import { stringAvatar } from '../../../../utils/stringAvatar';
import { useLocales } from '../../../../locales';
import Iconify from '../../../../components/iconify';
export default function ProtheseUserInfo({ data }) {
    const { translate } = useLocales();
    return (_jsxs(Card, { sx: {
            padding: '15px 30px',
            display: 'flex',
            flexDirection: 'row',
            gap: '40px',
            width: '100%',
            justifyContent: 'space-between',
        }, children: [_jsxs(Box, { sx: { display: 'flex', alignItems: 'flex-start', gap: '25px', justifyContent: 'center' }, children: [_jsx(Avatar, { src: data?.avatar, ...stringAvatar(`${data?.name}`), sx: { width: 70, height: 70, background: '#DAF6FF' } }), _jsxs(Box, { children: [_jsx(Typography, { variant: "h6", color: 'text.secondary', sx: { textTransform: 'capitalize' }, children: data?.name }), data?.phone && (_jsxs(Stack, { flexDirection: "row", alignItems: "center", gap: 1, children: [_jsx(Iconify, { icon: "ph:phone" }), _jsx(Typography, { variant: "body1", color: 'text.secondary', children: data?.phone })] })), data?.gender && (_jsxs(Stack, { flexDirection: "row", alignItems: "center", gap: 1, children: [_jsx(Iconify, { icon: "ph:gender-male-bold" }), _jsx(Typography, { variant: "body1", color: 'text.secondary', children: data?.gender })] }))] })] }), data?.createdBy?.cabinet?.name && (_jsxs(Box, { sx: { display: 'flex', alignItems: 'flex-start', gap: '25px', justifyContent: 'center' }, children: [_jsx(Avatar, { ...stringAvatar(`${data?.createdBy?.cabinet?.name}`), sx: { width: 70, height: 70, background: '#DAF6FF' } }), _jsxs(Box, { children: [_jsx(Typography, { variant: "h6", color: 'text.secondary', sx: { textTransform: 'capitalize' }, children: data?.createdBy?.cabinet?.name }), _jsx(Typography, { variant: "body1", color: 'text.secondary', children: data?.createdBy?.cabinet?.address })] })] })), _jsxs(Box, { sx: { width: '30%' }, children: [_jsx(Typography, { variant: "h6", color: 'text.secondary', children: `${translate('patient_card_info')}` }), _jsx(Typography, { variant: "body1", color: 'text.secondary', children: data?.description || '_' })] })] }));
}
